import { redirect, useRoutes } from "react-router-dom";
import Header from "./components/Header";

import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import { defaultRoute, router } from "./router";
import { useCountDown, useUpdate } from "ahooks";
import { day, ydsj2 } from "./utils/util";
import { useEffect, useState } from "react";
// @ts-ignore

const { chains, publicClient } = configureChains(
  [bsc],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "BNC20",
  projectId: "b616bd1cd361013b67d01a2d4dcf254e",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});



function App() {
  const r = useRoutes(router);
  const update = useUpdate();

  useCountDown({
    targetDate: ydsj2().toDate(),
    onEnd: () => {
      update()
    },
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} id="rainbow">
        <Header />
        {r}

        <div className="contact-icons-box flex flex-col mp:flex justify-center mp:pb-20 md:pb-0 md:fixed bottom-40 right-40">
          <a
            href="https://twitter.com/BmexBtc"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAAXNSR0IArs4c6QAAA6pJREFUeF7tmv1RFUEQxGci0AzUCMQIxAg0AzECJQIxAjECIQI1AiECIQIlA4xgrKbmcOvg3b3b3tnjVc1WUfzB9bH7256Z/TiVbBQBpdQplgRImiABJkCSAClPByZAkgApTwcmQJIAKU8HJkCSAClPByZAkgApTwcmQJIAKU8HJkCSAClPByZAkgApTwcmQJIAKU8HJkCSACnfaQea2UsRuVTVa5JDtXynAJrZYxH5KCJvRORpMWoA/C4ip6p6Nqbhur37/lZNzoVhAM1sX0QuWrnDzADtq4gA4lQ7VtVDPGBmgPxWRD6IyKGqnrDAxvpIgOjscxF5xUI0swOHt+34Lxz04NJzVcWENm+RAM17i8FUQzSzPRH5RYz8UkT2MYlwpKr+Id51RxoC8J5BA+I7VcXvRc3MkNteLxL9f/jKcyYmASkAfbiTIyvffSOLAohw+TnqGBL9ojzkOew3M8BCi/993Ohdt6+JAjgVdnAUBjMbSl44vjUYNJzXvICEOdArIBz3aGLwcMOXKZBmduQhWMvwLypwFLxogJhxLCHmGp5DXvoxrtYNAIZV32FQISHsDqypngCJQoMfhDhyKVxY23YXoENkQ7AW3KDbTYC+jME+9dR3Adh+rdF2FmC5jBk2+nNbsAjAn1SVSQGzfYrMgcNOZLYTgQ+ELV96FJFtq3AgP3m2zXqT6UCkA7GRb7WLqBnjlaqWR14175jVhAH0Krz0FGW2wwsewCIdx1ihLRTgyhBf1BxeLKUdDtAhDgtiLG16tPDlS3gRGf6BrwmH4yjkJIR1dMP5Y9Njq00dDneg30dgWzZ1sNASaDf3odPhAD2Ee27puuS+biFchHKPdWH4zmMcKl0cWEDEGeD7lvFavKtr6HZ3YAERhQQhjTuKVnnx9uIoaHI2vrarA8e9MDO4kb2nwKkzbt0WX1i1gL0KQF/afPYDU2YccN7BWvC6VWGvxDjOwkIa26sWl9znSAPspT0zeyEA/ZOO8Y4D+Q5H/C0aQvYo4oqypnMhIezXkchtT2o6NaG5OeFe23Vl/0IAFhUXzsPWrfbLArwKXxdgDXkSfbZXM9mhAAuQyH9DGCOUpw4VAAxbP1zAn61ZILYB2gXgpo4M3+2JyPVDB7VpDKsC3GaGH/ozCZCcoQSYAEkCpDwdmABJAqQ8HZgASQKkPB2YAEkCpDwdmABJAqQ8HZgASQKkPB2YAEkCpDwdmABJAqQ8HZgASQKkPB2YAEkCpDwdSAL8B0B5/lGbuqvnAAAAAElFTkSuQmCC"
              alt="twitter"
              className="contact-icon mp:w-60 mp:h-60 md:w-40 md:h-40 cursor-pointer ml-[12px]"
            />
          </a>
          <a 
          href="https://t.me/BmexBtc"
           target="_blank" rel="noreferrer">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAAXNSR0IArs4c6QAAA7hJREFUeF7tmeFx1DAQhXcrACoAKgAqIFRAqIBQAVABUAFJBZAKIBUkqYCkA9IBVLDMm5FvjE+2JD/pTrlZzdwva2Xpuye91VrFG0VAqWgPFgdIisABOkCSABnuCnSAJAEy3BXoAEkCZLgr0AGSBMhwV6ADJAmQ4a5AB0gSIMNdgQ6QJECGuwIdIEmADHcFOkCSABnuCnSAJAEy/OAVaGZPROS1iNyq6hXJayv8YAGaGaCdiMjxaNVvVPVnTYgHBTCo7X0A9zAC6ouqfnaAIwJmBlBQ2wcReZ6A4wAHQGYGWFAbtmhMbTGWr2qfg/dqCwe1vQ1qgzmUtkeq+qc0aKn/vQA4YwixdV2LyG8RAeRpu1PVNdAXeXcLcGQI2KKphd8FVSJNuZw5Cy9UdezIVYTYFcCRISD9OMpc4ZmIwFkB+ccC7I+qepo5Zna3LgCuNIRbqA6mEOKhvCUzqW4goLw3gIQh/BWR0yGfMzNsy28pJ1bVJmttMuiS/gsMYc4kTlQVRiFmhq0OeKmGa1wqR0yNEX2+E4DBEOCMWHDKEGITheoAbnMNK4CH8c5UFYl29dYUoJkN0HINIbbA83DWbfI3M4Pq8Gfktneq+j23c0m/JgDN7FNIK3JvCLE5IzWB6v6roKyAh7FfqOpNCZjcvtUBmhm2ytfcCcz027qzBtNBmlKs5lYG0sSFQ0qBs+rxCoi4SWxMYogP8OYS5NRrrlW1GHpq0OF5dQWOFg3XGxLiZ4kJwSSQ022dU+EPwZm31kWrV2DGa2kGcPyS4MLI1wB0CvMiqG7rkp+ZIKfEUr2IunOAE5gwFsDEDwlxtMxeCR5e/XTIG1Ok1zzfiQJLJ1aY4y0N36QCs1cFpmBWhIdXNanAdAswbNtfKcgFz5saSJM0pmBxW13NDOkG0pVarUkFpmcF1kjCx+urXsKf/rNdmYiZoTCKa2CN1qwC07MCYwCRZCPBxhe4knauqiUFh5KxN317VyDgHaEQEM5HgMy9IjYp4fe+hZFUvwyT3MAbJh3uxICID+mp1qwC0/MWHgCilHU8V4LKqfi0rMD0DhBXPWzbxQ/giapP0wpMzwCRB96k4E22ND5VTj+kNyvhd30Gpg61uefh+geQD0KfpgWEbhW4FiDigsGgwgMFNynfx+bXVRrDANxXrAMkyTtAB0gSIMNdgQ6QJECGuwIdIEmADHcFOkCSABnuCnSAJAEy3BXoAEkCZLgr0AGSBMhwV6ADJAmQ4a5AB0gSIMNdgQ6QJECGuwJJgP8ApdQMYIFXZe4AAAAASUVORK5CYII="
              alt="telegram"
              className="contact-icon mp:w-60 mp:h-60 md:w-40 md:h-40 cursor-pointer ml-[12px]"
            />
          </a>
          <a
            href="https://docs.bnc20rune.dev/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAAXNSR0IArs4c6QAABBZJREFUeF7tmo1t1EAQRmcqgA4gFUAqIFQAVECogFABpAJIBSQVkFQAqYCUkFQAVLDonXajxfh865vZOwtmJYR09tje529+HZVYJgJqsg5jCYBGEQTAAGgkYDQPBQZAIwGjeSgwABoJGM1DgQHQSMBoHgoMgEYCRvNQYAA0EjCahwIDoJGA0TwUGACNBIzmocAAaCRgNA8FBkAjAaN5KPB/AJhSeiwiL0TkYd7vlareGPfuYr5oBWZwH0Xk5chuv4nIqary/97WIgFmcO9F5LiBzF5BLgrgTHBDtrci8kFVLxqgu52yCIApJWLbWwA47GynIPcKsAJ3UiUIB4arSwDyk4hcqOpPr4sOr7MXgJ3BDfcIPECe9QC5U4A5xr0WkR6K2yQyQL5R1ctNJ845vhOAxuQwZz8t577yhNgV4MLAFbi3qnrQQrrlnC4AFwoOHte51HErvt0BppQoRwjaS1ru4MrmXAGmlKjj6CCWsrqBcweYUjoSka8LIXeXXfW89/O4KTClxMNSorCuRIRpCVOU8lvvvXD9nYHrocAfuZu4U1XArVZWJq79rCPBteBSSk9zWGGis+pOVPXM61k8FZjyQ9E6/TVFySBJLk+8Hl5EfmVXHU1auRr4PtImvlNVl0TnCZC3+wj3VdWx+V1RJHBRJOduuwAHANS0ts+tkhoK5b78I6TcqOrhtjev7TwBsiFKGNaBqgJ07UopbQOyCVwVPkpcpg8+SSnxYr9wXFVd9u5ykRzrGEkB7UFOIM9bmvesEnpj7KbW6SbFVeCIwcOBLDCpFDg26SVzlOkGMEO8f8MzIQIfiGMgGZAyKJ1UdPUS8YKpYQUqPvL6puIKMG8C1/yc3yLx6aR1SpyDPvZFzZct4PJ9y0C2fHjiZ2AVmChvNdpq8YxWFboDzJuhdGBsVBJFt+8WOa7x4em+dMqbb3b5Vlhj53UBWLkT2bYkFn4mDvElbaM7btpULouIc8S1ejW7/KZ7tBzvBrAK6KiRDF0X0oCkFps9ap+Y9HTve3eqwOHNsqsBsrj1rFF7/gyA4ohp9dp5+1bfvLsCR0Di1nW2/QNkpTAyOv00bRfdyzCzrmpCVfX4ktfiraPn7BzgRHwEJK5dsvDUpoBKaTM7BGxNao3hXgBW8ZHMiVvzdy/1Ql2ARHV17CTOHXskIS+QewVYgSST4ookHDqS0nrVM0Y6G7dR/D8FsAJZ+unizgAE6rWqDssVLwam6yxCgRVAughUNhx5HXq1XiZaI8aLAlglGOJfycLnS4XH8y4OoLdCel8vABoJB8AAaCRgNA8FBkAjAaN5KDAAGgkYzUOBAdBIwGgeCgyARgJG81BgADQSMJqHAgOgkYDRPBQYAI0EjOahwABoJGA0DwUGQCMBo3ko0AjwN+iwSGDgozmaAAAAAElFTkSuQmCC"
              alt="gitbook"
              className="contact-icon mp:w-60 mp:h-60 md:w-40 md:h-40 cursor-pointer ml-[12px]"
            />
          </a>
          {/* <a href="https://github.com/avascriptions" target="_blank" rel="noreferrer">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAAXNSR0IArs4c6QAAA3BJREFUeF7tmutxFDEQhKcjACIAIgAiAEcARIAdARABJgMTAXYEtiPAjgA7AnAEQARDdZW2am/vtbd9M37c6NdV3bYe3/ZKo5FgVSQCkNQltgIomqAAFkCRgCgvBxZAkYAoLwcWQJGAKC8HFkCRgCgvBxZAkYAoLwcWQJGAKC8HFkCRgCgvBxZAkYAoLwfeJ4Dufmhmr83swsyuAJyL/Z+Ru/szM3trZm/M7LGZvQfwd5ttDOtKdaC7/2kD6/rx28wOAZwog3T3l2b2xczeDeo5AHCs1L1OmwawDfLnkg5dmdlBcydh0El0EH/3C93EZ1no4sumobMXlRMA++sgKP9nAuRn9UPp7ATtJQC2G1YKoIj2oQM8BzCcF0Vks/I0gGzW3bloPN3qCFZX9uAWEc6BXByyyjEALk5hJc2B7k54oRP6Ekp7ALhih5QUgO5+GytwB+wCwF4IPbOcuzHuzmD2Q9QgRtT7HADn362XLAcOdyBbH8iaCsMWk3CAbX/6K5vYoL1vAD5F9CED4G3Ofx2zsB1JARRtuSsAw3Yk4QDbDsTFF63KvwJYlrGR6s4CyDTUI6mnmvgzgCOtisXqLIBnLVMcMYYxdd77OJBJze9jRhrwzA0AJmhDSooD2zyYnYnpgIUF0WwgEyDzcqchNlhe6TWA4bHAVruQBrC5MHNP/I9nKlF74O4tpAJsEBlO8AQtslyb2T6A7gAqrK10gA0it3fdGfE2B0fXMVw5ij4PDndgL4VFFzAOm0tqtqNOrtCcp3jgPqXctKNOhkpnWeAyADJ1Tzd0ecC1q6G7b7LQhCZKx77J8E+4Xefo5rxXq+Yld2faa5OYbe1LGQti6nPhAAcxID9nnlHM3VeZmPYPS1ONBZoFsJ8TZEBN58zMie4+abcCIGUMy4CmNe7uBNZfKOhGTvz8ZLmITAp4dwkgAS27XDT2i5l7bmcA9uI/rswvJhMbCHcKYDf2dtDEkKV/S4Gf8sZHnzsJcJH7BuHOaIMWwIaqAI72zOIHC2ABFAmIcnfn6vxxQjVPshMI/T6mBdKrwLTMzSZ74GF1vOkfdoVtVd/vBMAJrrszkgIovooCWABFAqK8HFgARQKivBxYAEUCorwcWABFAqK8HFgARQKivBxYAEUCorwcWABFAqK8HFgARQKivBxYAEUCovw/gj/2UeiXKQkAAAAASUVORK5CYII="
              alt="github"
              className="contact-icon mp:w-60 mp:h-60 md:w-40 md:h-40 cursor-pointer ml-[12px]"
            />
          </a> */}
        </div>
        {/* <RouterProvider router={router}  /> */}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
