import { Close as CloseIcon } from "@mui/icons-material";
import styles from "./index.module.less";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemText,
  Snackbar,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAccount, useSendTransaction, useSwitchNetwork } from "wagmi";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Hex, parseEther } from "viem";
import axios from "axios";
import { day, delay } from "../../utils/util";
import { useAsyncEffect } from "ahooks";
import { bsc } from "viem/chains";
import { useStoreContext } from "../../store";

const MyButton = styled(Button)(() => ({
  boxSizing: "border-box",
  borderRadius: "6px",
  height: "40px",
  background: "#ffffff",
  color: "#18191b",
  clipPath: `polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 12px,
    100% calc(100% - 12px),
    100% 100%,
    100% 100%,
    0 100%,
    0 0
  )`,
  fontSize: 16,
  fontFamily: "Chakra Petch, Chakra Petch-Bold",
  fontWeight: "bold",
  ":hover": {
    background: "#ffffff6f",
  },
  ":disabled": {
    background: "#ffffff6f !important",
  }
}));

const MyListItemText = styled(ListItemText)(() => ({
  color: "#bbbbbb",
  ".MuiTypography-root": {
    fontSize: 16,
    fontFamily: "Chakra Petch, Chakra Petch-Regular !important",
    fontWeight: "500 !important",
  }
 
}));

const Detail = () => {
  const { name } = useParams();
  const messageRef = useRef<ReactNode | string>("");
  const store = useStoreContext();

  const [data, setData] = useState<Record<string, any>>({});
  const account = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const MINT_STRING = `data:,{"p":"bnc-20","op":"mint","tick":"bmex","amt":"100"}`;

  const MINT_HEX = "0x" + Buffer.from(MINT_STRING).toString("hex");
  const [loading, setLoading] = useState(false);
  const { isLoading, sendTransaction } = useSendTransaction({
    to: "0xB2192D26FCaee34eCD82537505f7dE83cbD9d379",
    value: parseEther("0.001"),
    data: MINT_HEX as Hex,
    onSuccess(data, variables, context) {
      messageRef.current = (
        <div>
          <p>Transaction Success!</p>
          <p>Hash: <a href={`https://bscscan.com/tx/${data.hash}`}>{data.hash}</a></p>
        </div>
      );
      setOpen(true);
    },
    onError(error, variables, context) {
      messageRef.current = error.message;
      setOpen(true);
    },
  });

  const [open, setOpen] = useState(false);

  const onCopyText = () => {
    const textarea = document.createElement("textarea");
    textarea.value = MINT_STRING;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    messageRef.current = "Copy Success!";
    setOpen(true);
  };

  const onCopyHex = () => {
    const textarea = document.createElement("textarea");
    textarea.value = MINT_HEX;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    messageRef.current = "Copy Success!";
    setOpen(true);
  };

  const onMint = async() => {

    if (!account.isConnected) {
      messageRef.current = "Please connect wallet";
      setOpen(true);
    } else {
      delay(200);
      sendTransaction();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchToken = async () => {
    // const name = params?.name || "";
    const { data } = await axios.get(`/api/protocol/info/${name}`);
    if (data.code === 200) {
      store.setDetail(data.data)
      setData(data.data);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  useAsyncEffect(async() => {
    const chainId = await account.connector?.getChainId()
    if (chainId !== bsc.id) {
      switchNetwork?.(bsc.id)
    }
    // switchNetwork
  }, [])

  return (
    <div className={styles.page}>


      <div className={styles.overview}>
        <div className={styles.header}>
          <div className={styles.title}>Overview</div>

          <div className={styles.actions}>
            {/* <MyButton onClick={onCopyText}>Copy Mint Text</MyButton>
            <MyButton onClick={onCopyHex}>Copy Mint Hex</MyButton> */}
            <MyButton onClick={onMint} disabled={true}>Mint Directly</MyButton>
          </div>
        </div>

        <div className={styles.container}>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>210000000</span>
                }
              />
            }
          >
            <MyListItemText primary={`Total Supply`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>{data?.minted}</span>}
              />
            }
          >
            <MyListItemText primary={`Minted`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>100</span>
                }
              />
            }
          >
            <MyListItemText primary={`Limit Per Mint`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>{data?.decimal || 0}</span>
                }
              />
            }
          >
            <MyListItemText primary={`Decimal`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>{data?.deployer}</span>}
              />
            }
          >
            <MyListItemText primary={`Deploy By`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>
                    {data?.deployTime ? day(data?.deployTime).format("YYYY/MM/DD HH:mm:ss") : ''}
                  </span>
                }
              />
            }
          >
            <MyListItemText primary={`Deploy Time`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>{data?.holders}</span>}
              />
            }
          >
            <MyListItemText primary={`Holders`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>{data?.txCount}</span>}
              />
            }
          >
            <MyListItemText primary={`Total Transactions`} />
          </ListItem>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        message={<div className={styles.message}>{messageRef.current}</div>}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}

        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}

        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Detail;
