import axios from "axios";
import styles from "./index.module.less";
import { useAccount, useSendTransaction } from "wagmi";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Pagination,
  Snackbar,
  TextField,
  styled,
} from "@mui/material";
import classNames from "classnames";
import ClosePng from "./close.png";
import { Hex, isAddress, parseEther } from "viem";
import { isEmpty } from "../../utils/util";

const MyButton = styled(Button)(() => ({
  width: "117px",
  background: "#ffffff",
  borderRadius: "6px",
  color: "#18191b",
  clipPath: `polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 12px,
    100% calc(100% - 12px),
    100% 100%,
    100% 100%,
    0 100%,
    0 0
  )`,
  fontFamily: "Chakra Petch-Bold !important",
  fontSize: '16px',
  ":hover": {
    background: "#ffffff6f",
  },
  
  ":disabled": {
    background: "#ffffff6f !important",
  }
}));

const MyCloseButton = styled(Button)(() => ({
  width: "20px",
  height: "20px",
  padding: 0,
  img: {
    width: "20px",
  },
}));

const TickInput = styled(TextField)(() => ({
  boxSizing: "border-box",
  height: "74px",
  marginBottom: "40px",
  borderRadius: "8px",
  background: "#171718",
  ".Mui-disabled": {
    height: "74px",
    padding: "0 5px",
    background: "#171718",
    color: "#F0B90D !important",
    "-webkit-text-fill-color": "#F0B90D !important",
    fontSize: "20px",
    fontFamily: "Chakra Petch, Chakra Petch-Bold",
    fontWeight: "Bold"
  },
  ".MuiInputLabel-filled": {
    fontSize: "20px",
    fontWeight: "Bold",
    color: "#E84242 !important",
    "-webkit-text-fill-color": "#E84242 !important",
  },
  ".Mui-disabled:before": {
    display: "none",
    border: "none",
    borderBottomStyle: "none",
  },
  ".MuiFormLabel-root": {
    border: "none",
  },
  ".MuiInputBase-input": {
    color: "#bbbbbb",
    "-webkit-text-fill-color": "#bbbbbb",
  },
}));

const MyInput = styled(TextField)(() => ({
  boxSizing: "border-box",
  height: "56px",
  padding: 0,
  marginBottom: "40px",
  borderRadius: "8px",
  background: "#171718",
  border: "none",
  ".MuiFormLabel-root": {
    border: "none",
    color: "#fff",
  },
  ".MuiFilledInput-root:before": {
    display: "none",
  },
  ".MuiFilledInput-root:after": {
    display: "none",
  },
  ".MuiInputBase-input": {
    color: "#bbbbbb",
    padding: '0 5px',
    height: "56px",
    lineHieght: "56px",
    "-webkit-text-fill-color": "#bbbbbb",
    direction: "rtl"
  },
}));

const MyCancel = styled(Button)(() => ({
  textTransform: "none",
  width: "230px",
  height: "40px",
  marginRight: "20px",
  background: "#534e59",
  borderRadius: "6px",
  color: "#ffffff",
  clipPath: `polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 12px,
    100% calc(100% - 12px),
    100% 100%,
    100% 100%,
    0 100%,
    0 0
  )`,
  fontFamily: "Chakra Petch-Bold !important",
  fontSize: '16px',
  ":hover": {
    background: "#534e596f",
  },
}));

const MyTransferButton = styled(Button)(() => ({
  textTransform: "none",
  width: "230px",
  height: "40px",
  background: "#ffffff",
  borderRadius: "6px",
  clipPath: `polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 12px,
    100% calc(100% - 12px),
    100% 100%,
    100% 100%,
    0 100%,
    0 0
  )`,
  fontFamily: "Chakra Petch-Bold !important",
  fontSize: '16px',
  color: "#18191b",
  ":hover": {
    background: "#fffffff",
  },
}));

const MyInscription = () => {
  const params = useParams();

  const navigate = useNavigate();
  const account = useAccount({
    onDisconnect() {
      navigate("/");
    },
  });
  const messageRef = useRef<ReactNode | string>('');
  const [page, setPage] = useState(1);
  const [data, setData] = useState<{
    records?: Record<string, any>[];
    [key: string]: any;
  }>({});

  const [inscription, setInscription] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [amount, setAmount] = useState("");
  const [target, setTarget] = useState("");

  const onOpenSnackbar = () => {
    setOpenSnackbar((prev) => !prev);
  };

  const onOpen = (_inscription?: Record<string, any>) => {
    setInscription(_inscription || {});
    setAmount("");
    setTarget("");
    setOpen((prev) => !prev);
  };

  const fetchMyInscription = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/inscription/my/${params.address?.toLocaleLowerCase()}`,
        {
          params: {
            pageNum: page,
            pageSize: 8,
          },
        }
      );

      if (data.code === 200) {
        setData(data.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const MINT_STRING = `data:,{"p":"${inscription.protocol}","op":"transfer","tick":"${inscription.ticker}","amt":"${amount}"}`;

  const MINT_HEX = "0x" + Buffer.from(MINT_STRING).toString("hex");

  const { isLoading, sendTransaction } = useSendTransaction({
    to: target,
    value: parseEther("0"),
    data: MINT_HEX as Hex,
    onSuccess(data, variables, context) {
      messageRef.current = (
        <div>
          <p>Transaction Success!</p>
          <p>Hash: <a href={`https://bscscan.com/tx/${data.hash}`}>{data.hash}</a></p>
        </div>
      );
      // messageRef.current = "Transaction Success!";
      onOpenSnackbar();
      setOpen((prev) => !prev);
    },
    onError(error, variables, context) {
      messageRef.current = error.message;
      onOpenSnackbar();
    },
  });

  const onTranster = () => {
    if (!amount) {
      messageRef.current = "Please enter amount";
      onOpenSnackbar();
      return;
    }

    if (!target) {
      messageRef.current = "Please enter address";
      onOpenSnackbar();
      return;
    }

    if (+amount > inscription.balance) {
      messageRef.current = "Amount error";
      onOpenSnackbar();
      return;
    }

    if (!isAddress(target)) {
      messageRef.current = "Please enter address";
      onOpenSnackbar();
      return;
    }

    sendTransaction();
  };

  useEffect(() => {
    fetchMyInscription();
  }, [page]);

  return (
    <div className={styles.page}>
      <div className={styles.overview}>
        <div className={styles.main}>
          {data?.records?.map((item, index) => (
            <div
              className={styles.card}
              key={index}
            // onClick={() => toInscription(item.transactionHash)}
            >
              <div className={styles.card_header}>{item.ticker}</div>
              <div
                className={classNames(
                  styles.card_content,
                  "break-all whitespace-pre-wrap"
                )}
              >
                <pre>{item.balance}</pre>
              </div>

              <div className={styles.id}>{/* #{item.block} */}</div>
              <div className={styles.card_footer}>
                <MyButton variant="contained" onClick={() => onOpen(item)}  disabled={item.balance <= 0}>
                {/* <MyButton variant="contained" onClick={() => onOpen(item)}  disabled={true}> */}
                  Transfer
                </MyButton>

                <MyButton variant="contained" disabled={true}>
                  List
                </MyButton>
              </div>
            </div>
          ))}

          {isEmpty(data?.records || []) && (
            <div className={styles.load}>
              <div className={styles.no_more}>No data</div>
            </div>
          )}

          {/* {data?.pages > 0 && (
            <div className={styles.pagination}>
              <MyPagination
                page={page}
                count={data?.pages}
                variant="outlined"
                shape="rounded"
                onChange={(_, p) => setPage(p)}
              />
            </div>
          )} */}
        </div>

        <Backdrop
          sx={{ color: "#E84242", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          transitionDuration={200}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Modal
          open={open}
          onClose={(_, reason) => {
            if (reason === "backdropClick") return;
            onOpen();
          }}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
              <div className={styles.title}>
          
                Transfer {inscription.ticker} ({inscription.protocol}) for address
          
              </div>

              <MyCloseButton className={styles.close} onClick={onOpen}>
                <img src={ClosePng} alt="" />
              </MyCloseButton>
            </div>

            <TickInput
              disabled
              fullWidth
              // label={inscription.protocol}
              defaultValue={inscription.ticker}
              // defaultValue={`#${inscription.block}`}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <span className={styles.amount}>{inscription.balance}</span>
                ),
              }}
            />

            <div className={styles.input_box}>
              {/* <div className={styles.label}>Amount</div> */}
              <MyInput
                fullWidth
                variant="filled"
                placeholder="Please enter amount"
                value={amount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d+$/.test(value) || value === "") {
                    setAmount(value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <div className={styles.startAdornment_value}>Amount</div>
                  )
                }}
              />
            </div>

            <div className={styles.input_box}>
              <MyInput
                fullWidth
                variant="filled"
                placeholder="Please enter address"
                value={target}
                onChange={(e) => {
                  const value = e.target.value;

                  setTarget(value);
                }}
                InputProps={{
                  startAdornment: (
                    <div className={styles.startAdornment_value}>To</div>
                  )
                }}
              />
            </div>

            <div className={styles.actions}>
              <MyCancel onClick={onOpen}>Cancel</MyCancel>
              <MyTransferButton onClick={onTranster}>
                Transfer
              </MyTransferButton>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}
              open={isLoading}
            // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>

        </Modal>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={onOpenSnackbar}
        message={messageRef.current}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onOpenSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />


    </div>
  );
};

export default MyInscription;
