import {
  Button,
  Fade,
  LinearProgress,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import styles from "./index.module.less";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useState } from "react";
import {
  onAddressAbbreviation,
  pathIsDetail,
  pathIsTokens,
  pathNotToken2Detail,
} from "../../utils/util";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";

import LogoPng from "./logo.png";
import classNames from "classnames";
import { useStoreContext } from "../../store";

const MyAddress = styled(Button)(({ theme }) => ({
  height: 40,
  width: 126,
  backgroundColor: "#ffffff",
  borderRadius: 6,
  padding: 0,
  fontSize: 14,
  color: "#38383e",
  ":hover": {
    background: "#ffffff6f",
  },
}));

const MyMenu = styled(Menu)(({ theme }) => ({
  width: "175px",
  // backgroundColor: "#232325",
  backgroundColor: "transparent",
  ".MuiPaper-root": {
    width: "175px",
    backgroundColor: "transparent",
  },
  ".MuiList-root": {
    width: "175px",
    marginTop: "12px",
    backgroundColor: "#fff",
  },
}));

const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#18191B",
  backgroundColor: "#fff",
  fontFamily: "Chakra Petch-Bold",
  fontSize: 16,
}));

const MyConnectButton = styled(Button)(() => ({
  width: 120,
  background: "#ffffff",
  color: "#18191b",
  borderRadius: 6,
  ":hover": {
    background: "#ffffff6f",
  },
}));

const MyLinearProgress = styled(LinearProgress)(({ theme }) => ({
  background: "#fff",
  height: "10px",
  borderRadius: "5px",
  ".MuiLinearProgress-bar": {
    background: "#ffae0c",
  },
}));

const Header = () => {
  const store = useStoreContext();
  const { disconnect } = useDisconnect();
  const account = useAccount();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    disconnect();
    setAnchorEl(null);
  };

  const onMy = () => {
    navigate(`/my/${account.address}`);
  };

  const toHome = () => {
    navigate("/");
  };

  return (
    <div
      className={classNames(styles.header, {
        [styles.token_header]: pathNotToken2Detail(),
      })}
    >
      <div onClick={toHome} className={styles.header_left}>
        <div className={styles.logo}>
          <img src={LogoPng} alt="" />

          <span>BNC20</span>
        </div>
      </div>
      <div
        className={classNames(styles.header_middle, {
          [styles.token_header_middle]: pathNotToken2Detail(),
        })}
      >
        {pathIsTokens() && (
          <>
            <div className={styles.h1}>
              Check out BNC-20 balance of the address
            </div>
            <div className={styles.h2}>
              Recognize all operations including DEPLOY, MINT and TRANSFER
            </div>
          </>
        )}
        {pathIsDetail() && (
          <>
            <div className={styles.name}>{store?.detail?.token || ''}</div>
            <div className={styles.progress}>
              <div className={styles.linear}>
                <MyLinearProgress variant="determinate" value={store?.detail?.progress || 0} />
              </div>
              <span>{store?.detail?.progress || 0}%</span>
            </div>
          </>
        )}
        {!pathNotToken2Detail() && (
          <div className={styles.menus}>
            <Button variant="text">
              <NavLink
                to="/"
                className={({ isActive, isPending }) =>
                  isActive ? styles.active : styles.link
                }
              >
                Inscriptions
              </NavLink>
            </Button>
            <Button variant="text">
              <NavLink
                to="/tokens"
                className={({ isActive, isPending }) =>
                  isActive ? styles.active : styles.link
                }
              >
                Tokens
              </NavLink>
            </Button>
          </div>
        )}
      </div>
      <div className={styles.header_right}>
        <div className={styles.menus}>
          {pathNotToken2Detail() && (
            <>
              <Button variant="text">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    isActive ? styles.active : styles.link
                  }
                >
                  Inscriptions
                </NavLink>
              </Button>
              <Button variant="text">
                <NavLink
                  to="/tokens"
                  className={({ isActive, isPending }) =>
                    isActive ? styles.active : styles.link
                  }
                >
                  Tokens
                </NavLink>
              </Button>
            </>
          )}

          <ConnectButton.Custom>
            {({
              account,
              chain,
              openAccountModal,
              openChainModal,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              if (!account) {
                return (
                  <MyConnectButton
                    className={styles.connect}
                    color="inherit"
                    onClick={openConnectModal}
                  >
                    Connect
                  </MyConnectButton>
                );
              }
              return (
                <div>
                  <MyAddress
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className={styles.address}
                  >
                    {/* <Blockies
                      scale={2.5}
                      seed={account.address?.toLowerCase()}
                    /> */}
                    {onAddressAbbreviation(account.address)}
                  </MyAddress>
                  <MyMenu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                   
                      <MyMenuItem onClick={onMy}>My Inscriptions</MyMenuItem>
                    
                    <MyMenuItem onClick={onLogout}>Logout</MyMenuItem>
                  </MyMenu>
                </div>
              );
            }}
          </ConnectButton.Custom>
        </div>
      </div>
    </div>
  );
};

export default Header;
