import Tokens from "./pages/Tokens";
import Detail from "./pages/detail";
import Inscription from "./pages/Inscription";
import MyInscription from "./pages/MyInscription";
import Home from "./pages/Home";

export const TOKENS_PATH = "/tokens";
export const DETAIL_PATH = "/detail/:name";

export const router = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: DETAIL_PATH,
      element: <Detail />,
    },
    {
      path: TOKENS_PATH,
      element: <Tokens />,
    },
    {
      path: "/inscription/:hash",
      element: <Inscription />,
    },
    {
      path: "/my/:address",
      element: <MyInscription />,
    },
  ];
  
  export const defaultRoute = [
    {
      path: "/*",
      element: <Tokens />,
    },
  ]