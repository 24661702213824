import classNames from "classnames";
import styles from "./index.module.less";
import { useRef } from "react";
import { useInfiniteScroll } from "ahooks";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import { day, onAddressAbbreviation } from "../../utils/util";

interface Result {
  list: Record<string, any>[];
  records: Record<string, any>[];
  current: number | undefined;
  pages: number | undefined;
}

const Home = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const getInscription = async (page: number = 1): Promise<Result> => {
    const { data } = await axios.get("api/inscription/list", {
      params: {
        pageNum: page,
        pageSize: 20,
      },
    });
    return new Promise((resolve) => {
      resolve({
        list: data.data?.records || [],
        ...data.data,
      });
    });
  };

  const { data, loadingMore, noMore } = useInfiniteScroll<Result>(
    (d) => {
      const page = d?.current ? d?.current + 1 : 1;

      return getInscription(page);
    },
    {
      target: ref,
      isNoMore: (d) => (d?.current || 1) >= (d?.pages || 1),
    }
  );

  const toInscription = (hash: string) => {
    navigate(`/inscription/${hash}`);
  };

  return (
    <div className={styles.page}>
      <div className={styles.main} ref={ref}>
        {data?.list?.map((item, index) => {
          let inputData: Record<string, any> = {};
          try {
            const _inputData = JSON.parse(item.content.replace("data:,", ""));

            Object.keys(_inputData).forEach((key) => {
              if (key !== "sign") {
                inputData[key] = _inputData[key];
              }
            });
          } catch (e) {
            inputData = {};
          }

          return (
            <div
              key={index}
              className={styles.card}
              onClick={() => toInscription(item.transactionHash)}
            >
              <div className={styles.card_header}>
                <div className={styles.title}>{item.ticker}</div>
                <div className={styles.time}>
                  {day(item.timestamp).fromNow()}
                </div>
              </div>
              <div
                className={classNames(
                  styles.card_content,
                  "break-all whitespace-pre-wrap"
                )}
              >
                <pre>{JSON.stringify(inputData, null, 2)}</pre>
              </div>

              <div className={styles.card_footer}>
                {/* <span className="text-white">#{item.block}</span> */}
                <span className="text-white">
                  {onAddressAbbreviation(item.fromAddress)}
                </span>
              </div>
            </div>
          );
        })}

        <div className={styles.load}>
          {
            !noMore ? (
              // <button type="button" onClick={loadMore} disabled={loadingMore}>
              loadingMore ? (
                <CircularProgress />
              ) : null
            ) : (
              <div className={styles.no_more}>No more data</div>
            )
            // </button>
          }
        </div>
      </div>

      {/* {loading && <CircularProgress />} */}
    </div>
  );
};

export default Home;
