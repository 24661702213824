import { createContext, useContext, useState } from "react";

export const StoreContext = createContext<{
    detail: Record<string, any>,
    setDetail: React.Dispatch<React.SetStateAction<{}>>
}>({
    detail: {},
    setDetail: () => {},
  })
  
  export const useStoreContext = () => useContext(StoreContext)
  
  export const StoreProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    // const [loading, setLoading] = useState(true);
    // useAsyncEffect(async() => {
    //   await loadWasm();
    //   delay(500);
    //   setLoading(false);
    // }, []);

    const [detail, setDetail] = useState({})
  
    return (
        <StoreContext.Provider value={{
            detail: detail,
            setDetail: setDetail
        }}>
        {props.children}
  
        </StoreContext.Provider>
    );
  };