import {
  Backdrop,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./index.module.less";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { day, ydsj2 } from "../../utils/util";
import Countdown from "react-countdown";

const MyTable = styled(Table)(({ theme }) => ({
  borderRadius: "16px",
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  backgroundColor: "transparent",
}));

const MyTableHeaderCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: "#2c2c2c",
  color: "#BBBBBB",
  fontSize: "16px",
  fontFamily: "Chakra Petch, Chakra Petch-Medium",
}));

const MyTableRow = styled(TableRow)(({}) => ({
  cursor: "pointer",
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: "#2c2c2c",
  color: "#fff",
  fontSize: "16px",
  fontFamily: "Chakra Petch, Chakra Petch-Regular",
  fontWeight: "Regular",
}));

const MyLinearProgress = styled(LinearProgress)(({ theme }) => ({
  background: "#fff",
  borderRadius: "3px",
  ".MuiLinearProgress-bar": {
    background: "#ffae0c",
  },
}));

const Tokens = () => {
  const navigate = useNavigate();
  const [list, setList] = useState<Record<string, any>[]>([]);

  const getList = async () => {
    const { data } = await axios.get("/api/protocol/list", {
      params: {
        pageNum: 1,
        pageSize: 20,
      },
    });

    if (data.code === 200) {
      setList(data.data.records);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.main}>
        {new Date().getTime() < ydsj2().toDate().getTime() && (
          <div className={styles.backdrop}>
            <Countdown date={ydsj2().toDate()} />
          </div>
        )}
        <div className={styles.title}>The full list of tokens</div>

        <MyTable sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <MyTableHeaderCell>Tokens</MyTableHeaderCell>
              <MyTableHeaderCell align="center">Deploy Time</MyTableHeaderCell>
              <MyTableHeaderCell align="center">Progress</MyTableHeaderCell>
              <MyTableHeaderCell align="center">Holders</MyTableHeaderCell>
              <MyTableHeaderCell align="right">Transactions</MyTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <MyTableRow
                key={row.token}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  navigate(`/detail/${row.token}`);
                }}
              >
                <MyTableCell component="th" scope="row">
                  <div className={styles.symbol}>{row.token}</div>
                </MyTableCell>
                <MyTableCell align="center">
                  {
                    <div className={styles.deploy}>
                      {day(row.deployTime).format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  }
                </MyTableCell>
                <MyTableCell align="center">
                  <MyLinearProgress
                    variant="determinate"
                    value={row.progress}
                    className={styles.progress}
                  />
                  <div className={styles.progress_value}>{row.progress}%</div>
                </MyTableCell>
                <MyTableCell align="center">{row.holders}</MyTableCell>
                <MyTableCell align="right">{row.txCount}</MyTableCell>
              </MyTableRow>
            ))}

            {list.length <= 0 && (
              <MyTableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  navigate(`/detail/bmex`);
                }}
              >
                <MyTableCell component="th" scope="row">
                  <div className={styles.symbol}>BMEX</div>
                </MyTableCell>
                <MyTableCell align="center">
                  {
                    <div className={styles.deploy}>
                      {ydsj2().format("YYYY/MM/DD HH:mm:ss")}
                    </div>
                  }
                </MyTableCell>
                <MyTableCell align="center">
                  {/* <div className={styles.progress}> */}

                  <MyLinearProgress
                    variant="determinate"
                    value={0 / 210000000}
                    className={styles.progress}
                  />
                  <div className={styles.progress_value}>0%</div>
                  {/* </div> */}
                </MyTableCell>
                <MyTableCell align="center">{0}</MyTableCell>
                <MyTableCell align="right">{0}</MyTableCell>
              </MyTableRow>
            )}
          </TableBody>
        </MyTable>
      </div>

      <div className={styles.footer}>
        <div className={styles.footer_container} />
      </div>
    </div>
  );
};

export default Tokens;
