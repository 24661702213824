import dayjs, { Dayjs } from "dayjs";
import { DETAIL_PATH, TOKENS_PATH } from "../router";
import { matchPath } from "react-router-dom";

/** 地址缩写 */
export const onAddressAbbreviation = (address: string) => {
  return (
    address.substring(0, 5) + "..." + address.substring(address.length - 4)
  );
};


export const day = (d: string | Dayjs | Date) => {
  return dayjs.utc(d).local();
};

// @ts-ignore
export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function ydsj2() {
  return day("2023-12-27 12:00:00")
}


export const pathIsTokens = () => {
  return !!matchPath(TOKENS_PATH, window.location.pathname)
}

export const pathIsDetail = () => {
  return !!matchPath(DETAIL_PATH, window.location.pathname)
}

export const pathNotToken2Detail = () => {
  return pathIsTokens() || pathIsDetail();
}