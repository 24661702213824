import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./index.module.less";
import classNames from "classnames";
import { ListItem, ListItemText, styled } from "@mui/material";
import { day, onAddressAbbreviation } from "../../utils/util";

const MyListItemText = styled(ListItemText)(() => ({
  color: "#bbbbbb",

  //   font-size: 16px;
  // font-family: Chakra Petch, Chakra Petch-Regular;
  // font-weight: Regular;
  ".MuiTypography-root": {
    fontSize: "16px !important",
    fontFamily: "Chakra Petch, Chakra Petch-Regular !important",
  },
}));

const Inscription = () => {
  const params = useParams();
  const [data, setData] = useState<Record<string, any>>({});

  const fetchInscription = async () => {
    const { data } = await axios.get(`/api/inscription/info/${params.hash}`);
    if (data.code === 200) {
      setData(data.data);
    }
  };

  useEffect(() => {
    fetchInscription();
  }, []);

  let inputData: Record<string, any> = {};
  try {
    const _inputData = JSON.parse(data.content.replace("data:,", ""));

    Object.keys(_inputData).forEach((key) => {
      if (key !== "sign") {
        inputData[key] = _inputData[key];
      }
    });
  } catch (e) {
    inputData = {};
  }

  return (
    <div className={styles.page}>
      <div className={styles.main}>
        <div className={styles.container}>
          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>
                    <a href={`https://bscscan.com/address/${data.toAddress}`}>
                      {data.fromAddress}
                    </a>
                  </span>
                }
              />
            }
          >
            <MyListItemText primary={`From`} />
          </ListItem>
          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>
                    <a href={`https://bscscan.com/address/${data.toAddress}`}>
                      {data.toAddress}
                    </a>
                  </span>
                }
              />
            }
          >
            <MyListItemText primary={`To`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>
                    {" "}
                    <a href={`https://bscscan.com/tx/${data.transactionHash}`}>
                      {data.transactionHash}
                    </a>
                  </span>
                }
              />
            }
          >
            <MyListItemText primary={`Hash`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>text/plain</span>}
              />
            }
          >
            <MyListItemText primary={`Mime Type`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={
                  <span className={styles.value}>
                    {day(data.timestamp).format("YYYY/MM/DD HH:mm:ss")}
                  </span>
                }
              />
            }
          >
            <MyListItemText primary={`Created`} />
          </ListItem>

          <ListItem
            disableGutters
            secondaryAction={
              <ListItemText
                primary={<span className={styles.value}>{data.block}</span>}
              />
            }
          >
            <MyListItemText primary={`Block`} />
          </ListItem>
        </div>

        {/* <div
          className={classNames(
            styles.content,
            "break-all whitespace-pre-wrap"
          )}
        >
          <pre>{JSON.stringify(inputData, null, "\t")}</pre>
        </div> */}

        <div className={styles.card}>
          <div className={styles.card_header}>
            <div className={styles.title}>{data.ticker}</div>
            <div className={styles.time}>{day(data.timestamp).fromNow()}</div>
          </div>
          <div
            className={classNames(
              styles.card_content,
              "break-all whitespace-pre-wrap"
            )}
          >
            <pre>{JSON.stringify(inputData, null, 2)}</pre>
          </div>

          <div className={styles.card_footer}>
            {/* <span className="text-white">#{item.block}</span> */}
            <span className="text-white">
              {onAddressAbbreviation(data?.fromAddress || '')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inscription;
